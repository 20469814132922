export function orderPurchases() {
    return axios({
        url: 'api/statistic/order/purchases',
        method: 'GET',
    });
}

export function earningIncome(ids) {
    return axios({
        url: 'api/statistic/earnings/income',
        method: 'POST',
        data: {
            ids: ids
        }
    });
}
